import { ArrowSmallUpIcon } from '@heroicons/react/20/solid'
import React, { useMemo } from 'react'

import Address from '../../assets/footer/address.svg'
import Email from '../../assets/footer/email.svg'
import Facebook from '../../assets/footer/fb.svg'
import Instagram from '../../assets/footer/ig.svg'
import Linkedin from '../../assets/footer/in.svg'
import Twitter from '../../assets/footer/x.svg'
import Phone from '../../assets/footer/phone.svg'
import {
  ContactUsPath,
  FooterItems,
  LoanApplicationPath,
  OurLoansPath,
  AboutUs as AboutUsPath
} from './navigationItems'
import { Button } from '../foundation/Button'
import shouldShowApplicationFlow from '../../utils/shouldShowApplicationFlow'

export default function Footer() {
  const currentPath = window.location.pathname
  const currentYear = new Date().getFullYear()

  const currentPathExtraItems = useMemo(() => {
    switch (currentPath) {
      case AboutUsPath:
        return [
          'Includes all customers from the prior companies Brother Loan & Finance, ' +
            'Checkmate Lending Solutions, and Crystal Rock Finance; ' +
            'the predecessors to Index Loans.'
        ]
      case OurLoansPath:
        return [
          'Subject to credit approval and verification. Approved loan amount and terms are ' +
            'dependent on our underwriting guidelines, credit policies, and by state regulation. ' +
            'Index Loans is not a lender in all states. Generally, funds may be delivered by ACH ' +
            'transfer as soon as the same business day if final approval occurs before 1:00 PM CT ' +
            'on a business day, subject to your bank’s processing times. If final approval occurs ' +
            'after 1:00 PM CT on a business day or on a non-business day, funds may be delivered ' +
            'as soon as the next business day. Some applications may require additional verification. ' +
            'In such cases, funds may be delayed until additional verifications are completed.'
        ]
      case '/':
        return [
          'Includes all customers from the prior companies Brother Loan & Finance, ' +
            'Checkmate Lending Solutions, and Crystal Rock Finance; ' +
            'the predecessors to Index Loans.',
          'Subject to credit approval and verification. Approved loan amount and terms are ' +
            'dependent on our underwriting guidelines, credit policies, and by state regulation. ' +
            'Index Loans is not a lender in all states. Generally, funds may be delivered by ACH ' +
            'transfer as soon as the same business day if final approval occurs before 1:00 PM CT ' +
            'on a business day, subject to your bank’s processing times. If final approval occurs ' +
            'after 1:00 PM CT on a business day or on a non-business day, funds may be delivered ' +
            'as soon as the next business day. Some applications may require additional verification. ' +
            'In such cases, funds may be delayed until additional verifications are completed.'
        ]
      default:
        return []
    }
  }, [])

  const memoShouldShowApplicationFlow = useMemo(() => shouldShowApplicationFlow(), [])

  const filteredSecondaryItems = memoShouldShowApplicationFlow
    ? FooterItems.secondary
    : FooterItems.secondary.filter((item) => item.path !== LoanApplicationPath)
  return (
    <footer className="bg-dark text-white" aria-labelledby="footer-heading">
      <div className="container pt-32px lg:py-64px">
        {/* links */}
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16">
          <div className="grid grid-cols-2 md:gap-8">
            <div>
              <ul role="list" className="mt-6 space-y-4">
                {FooterItems.primary.map((item) => (
                  <li key={item.label}>
                    <a
                      href={item.path}
                      className="whitespace-normal leading-6 text-gray_athens hover:text-white sm:whitespace-nowrap md:text-[16px] lg:text-[18px]"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <ul role="list" className="mt-6 space-y-4">
                {filteredSecondaryItems.map((item) => (
                  <li key={item.label}>
                    <a
                      href={item.path}
                      className="block whitespace-nowrap text-[16px] leading-6 text-gray_athens hover:text-white lg:text-[18px]"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
            <div>
              <ul role="list" className="mt-6 space-y-4">
                <li className="flex items-start">
                  <img src={Email.src} className="h-6 w-6" alt="email" />
                  <div className="pl-16px">
                    <a
                      className="block text-[16px] text-gray hover:text-white lg:text-[18px]"
                      href={`mailto:${import.meta.env.PUBLIC_EMAIL}`}
                    >
                      {import.meta.env.PUBLIC_EMAIL}
                    </a>
                  </div>
                </li>
                <li className="flex items-start">
                  <img src={Phone.src} className="h-6 w-6" alt="phone" />
                  <div className="pl-16px">
                    <a
                      className="text-[16px] text-gray hover:text-white lg:text-[18px]"
                      href={`tel:${import.meta.env.PUBLIC_PHONE_NUMBER}`}
                    >
                      {import.meta.env.PUBLIC_PHONE_NUMBER}
                    </a>
                  </div>
                </li>
                <li className="flex items-start">
                  <img src={Address.src} className="h-6 w-6" alt="address" />
                  <div className="pl-16px text-[16px] text-gray hover:text-white lg:text-[18px]">
                    {import.meta.env.PUBLIC_ADDRESS}
                  </div>
                </li>
              </ul>
            </div>
            <div className="mt-6 flex flex-row space-x-4 md:flex-col md:space-x-0 md:space-y-4 lg:items-end">
              <Button
                href={ContactUsPath}
                variant="primary"
                color="yellow"
                className="h-[44px] w-[150px] !font-medium md:w-[110px] md:px-0"
              >
                Contact Us
              </Button>
              {memoShouldShowApplicationFlow && (
                <Button
                  href={LoanApplicationPath}
                  variant="primary"
                  color="dark"
                  className="h-[44px] w-[150px] !font-medium md:w-[110px] md:px-0"
                >
                  Apply Now
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* some logos */}
        <div className="mt-8 border-t border-white/10 lg:flex lg:items-center lg:justify-between">
          <div className="mt-4 flex items-center gap-6 text-sm leading-6">
            <a
              href="https://www.bbb.org/us/il/summit/profile/consumer-finance-companies/index-loans-llc-0654-1000049782/#sealclick"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img
                src="https://seal-chicago.bbb.org/seals/black-seal-280-80-bbb-1000049782.png"
                alt="Index Loans LLC BBB Business Review"
                className="w-[200px] md:w-[250px]"
                loading="lazy"
                width={250}
                height={52}
              />
            </a>
            <script src="https://stage.ola-memberseal.org/js/seal.js?seal=member&token=OsKrK9cnfyr3dTyTxhqI&sealwidth=100&sealheight=100"></script>
          </div>
        </div>
        {!!currentPathExtraItems.length && (
          <div className="mt-4 flex flex-col border-t border-white/10 py-8 text-sm md:flex-row md:justify-between md:text-[16px]">
            <div>
              <ul role="list" className="mt-1 space-y-4 text-chateau_gray">
                {currentPathExtraItems.map((item, index) => (
                  <li key={index}>
                    <p>
                      <sup>{index + 1}</sup> {item}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className="mt-8 flex flex-col border-t border-white/10 py-8 text-sm md:flex-row md:justify-between md:text-[16px]">
          <p className="text-chateau_gray">
            © {currentYear} Index Loans, LLC. All Rights Reserved.
            <br />
            Use of this website is subject to Index Loans, LLC&apos;s
            <a href="/terms-of-use" target="_blank" rel="noreferrer">
              {' '}
              Terms of Use
            </a>{' '}
            and
            <a href="/privacy-policy" target="_blank" rel="noreferrer">
              {' '}
              Privacy Policy
            </a>
          </p>
          <p className="mt-6 flex items-center gap-4 md:mt-0">
            <a
              href="https://www.facebook.com/people/Index-Loans/100090187190455"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook.src} alt="facebook" loading="lazy" width={24} height={25} />
            </a>
            <a href="https://www.instagram.com/indexloans" target="_blank" rel="noreferrer">
              <img src={Instagram.src} alt="instagram" loading="lazy" width={24} height={25} />
            </a>
            <a href="https://www.linkedin.com/company/index-loans" target="_blank" rel="noreferrer">
              <img src={Linkedin.src} alt="linkedin" loading="lazy" width={24} height={25} />
            </a>
            <a href="https://twitter.com/indexloans" target="_blank" rel="noreferrer">
              <img src={Twitter.src} alt="twitter" loading="lazy" width={24} height={25} />
            </a>
            <a href="#" className="ml-auto flex items-center text-[14px] uppercase text-light_mint">
              Back to the top
              <ArrowSmallUpIcon className="text-gray-400 h-6 w-6 flex-none" aria-hidden="true" />
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}
